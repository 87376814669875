<template>
	<b-container fluid>
		<b-row>
			<b-col sm="12">
				<card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Student Report</h4>
					</template>
					<template v-slot:headerAction>
						<div class="float-right"></div>
					</template>
					<template v-slot:body>
						<b-form @submit.prevent="fetchStudents()">
							<b-row>
								    <div class="form-group col-lg-3 col-md-4">
										<label>State <span class="required">*</span></label>
										<b-form-select v-model="state" v-bind:class="{ 'is-invalid': Boolean(errors.state) }" @change="fetchDistricts()">
											<template #first>
												<b-form-select-option value="" disabled>-- Select state --</b-form-select-option>
											</template>
											<b-form-select-option v-for="state in states" :key="state.id" :value="state.id">{{ state.state }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.state" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>District <span class="required">*</span></label>
										<b-form-select v-model="district" v-bind:class="{ 'is-invalid': Boolean(errors.district) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select district --</b-form-select-option>
											</template>
											<b-form-select-option v-for="district in districts" :key="district.id" :value="district.id">{{ district.district }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.district" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Department <span class="required">*</span></label>
										<b-form-select v-model="department" v-bind:class="{ 'is-invalid': Boolean(errors.department) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select department --</b-form-select-option>
											</template>
											<b-form-select-option v-for="department in departments" :key="department.id" :value="department.id">{{ department.department }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.department" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Course <span class="required">*</span></label>
										<b-form-select v-model="course" v-bind:class="{ 'is-invalid': Boolean(errors.course) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select course --</b-form-select-option>
											</template>
											<b-form-select-option v-for="course in courses" :key="course.id" :value="course.id">{{ course.course }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.course" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Branch <span class="required">*</span></label>
										<b-form-select v-model="branch" v-bind:class="{ 'is-invalid': Boolean(errors.branch) }" @change="fetchSubBranches()">
											<template #first>
											<b-form-select-option value="" disabled>-- Select branch --</b-form-select-option>
											</template>
											<b-form-select-option v-for="branch in branches" :key="branch.id" :value="branch.id">{{ branch.branch }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.branch" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Sub Branch <span class="required">*</span></label>
										<b-form-select v-model="sub_branch" v-bind:class="{ 'is-invalid': Boolean(errors.sub_branch) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select sub branch --</b-form-select-option>
											</template>
											<b-form-select-option v-for="sub_branch in sub_branches" :key="sub_branch.id" :value="sub_branch.id">{{ sub_branch.subbranch }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.sub_branch" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Session <span class="required">*</span></label>
										<b-form-select v-model="session" v-bind:class="{ 'is-invalid': Boolean(errors.session) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select session --</b-form-select-option>
											</template>
											<b-form-select-option v-for="session in sessions" :key="session.id" :value="session.id">{{ session.start }}-{{ session.end }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.session" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>

									<div class="form-group col-lg-3 col-md-4">
										<label>Centre <span class="required">*</span></label>
										<b-form-select v-model="centre" v-bind:class="{ 'is-invalid': Boolean(errors.centre) }">
											<template #first>
											<b-form-select-option value="" disabled>-- Select centre --</b-form-select-option>
											</template>
											<b-form-select-option v-for="centre in centres" :key="centre.id" :value="centre.id">{{ centre.centre }}</b-form-select-option>
										</b-form-select>
										<div v-for="(val, index) in errors.centre" :key="index" class="invalid-feedback">
										<p class="mb-0">{{ val }}</p>
									    </div>
									</div>
									<b-button type="submit" class="mr-3" variant="primary">View</b-button>
							</b-row>

						</b-form>
					</template>
				</card>
			</b-col>

<!-- Table Card starts here-->
			<b-col sm="12" v-if="table_visible">
				<card>
					<template v-slot:headerTitle>
						
					</template>
					<template v-slot:headerAction>
						<b-button variant="primary" class="btn mt-2 mr-1 btn-sm" @click="generatePDF()" v-if="Boolean(students.length)">Download PDF</b-button>
					</template>
					<template v-slot:body>
						<!----- Listing length and search starts ----->
						<div class="row listing-length-row">
							<div class="col-sm-12 col-md-6">
								<div class="listing-length" >
									<label
										>Show
										<select class="form-control" v-model="listing_length" @change="fetchStudents()">
											<option value="5">5</option>
											<option value="10">10</option>
											<option value="15">15</option>
										</select>
										entries
									</label>
								</div>								
							</div>
							<div class="col-sm-12 col-md-6">
								<div class="div-search">
									<label>
										<input type="text" class="form-control" placeholder="Search" v-model="keyword" @keyup="fetchStudents()" />
									</label>
								</div>
							</div>
						</div>
						<!----- Listing length and search ends ----->

						<!--- Listing starts ----->
						<div class="table-responsive" >
							<table id="datatable" class="table data-table table-striped table-bordered dataTable">
								<thead>
									<tr>
										<th>Enrl. No</th>
										<th>Name</th>									
										<th>Contact</th>
										<th>Course</th>
										<th>Created on</th>
										<th>Options</th>
									</tr>
								</thead>
								<tbody v-if="Boolean(students.length)">
									<tr v-for="student in students" :key="student.id">
										<td>{{ student.enrollment_number }}</td>
										<td>{{ student.name }}</td>							<td>
											{{ student.mobile }}<br>
											{{ student.email }}
										</td>
										<td>{{ student.course }}<br>{{ student.start }}-{{ student.end }}</td>
										<td>{{ student.created_at | formatDateTime }}</td>
										<td>
											<router-link :to="{ name: 'students.details', params: { id: student.id }}" class="btn btn-primary mt-2 mr-1 btn-sm">Details</router-link>
										</td>
									</tr>
								</tbody>
								<tbody  v-else>
									<tr>
								   	   <td colspan="6" class="text-center">No data found!</td>
								    </tr>
								</tbody>
							</table>
						</div>
						<!-- Listing ends -->
						<!----pagination starts --->
						<nav aria-label="Page navigation">
							<ul class="pagination">
								<li class="page-item" v-bind:class="[{ disabled: !page.url }, { active: page.active }]" v-for="(page, index) in pagination" :key="page.label">
									<a class="page-link" href="#" @click.prevent="fetchStudents(page.url + '&limit=' + listing_length + '&keyword=' + keyword + '&course_id=' + course + '&session_id=' + session  + '&state_id=' + state + '&district_id=' + district + '&branch_id=' + branch + '&subbranch_id=' + sub_branch + '&department_id=' + department + '&centre_id=' + centre)" tabindex="-1">
										<span v-if="index == 0">Prev</span>
										<span v-else-if="index == pagination.length - 1">Next</span>
										<span v-else>{{ page.label }}</span>
									</a>
								</li>
							</ul>
						</nav>
						<!----pagination ends --->
					</template>
				</card>
			</b-col>
<!--Table card ends here-->			
		</b-row>
	</b-container>
</template>
<script>
	export default {
		name: "StudentReport",
		data() {
			return {
				students: [],

				state: '',
				district: '',
				centre: '',
				department: '',
				course: '',
				branch: '',
				sub_branch: '',
				session: '',
				keyword: "",
				report_status: '',
				table_visible: false,
				showsearch: false,
				listing_length: 10,
				token: "",
				errors: {},

				states: [],
				districts: [],
				departments: [],
				branches: [],
				sub_branches: [],
				courses: [],
				centres: [],
                sessions: [],
			};
		},

		created() {
			this.fetchStates();
			this.fetchDepartments();
			this.fetchSessions();
			this.fetchCourses();
			this.fetchCentres();
			this.fetchBranches();
			// this.fetchStudents();
		},

		methods: {

			getToken() {
				if (localStorage.token) {
					this.token = localStorage.token;
				} else {
					this.$vToastify.error("Session expired, please login again");
					this.$router.push({ name: "auth.login" });
				}
			},

			fetchStates() {
				let url = "/api/reports/states";			
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.states = responseData.data;
					})
					.catch((err) => console.log(err));
			},
            
            fetchDistricts() {	
                // alert(this.state);			
				let url = "/api/reports/districts?state_id=" + this.state;	
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.districts = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchSessions() {
				let url = "/api/reports/sessions";				
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.sessions = responseData.data;
					})
					.catch((err) => console.log(err));
			},
            
            fetchBranches() {
				let url = "/api/reports/branches";				
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.branches = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchSubBranches() {				
				let url = "/api/reports/sub_branches?branch_id=" + this.branch;	
				this.getToken();
				this.$axios
					.get(url, { headers: { Authorization: "Bearer " + this.token } })
					.then((res) => {
						var responseData = res.data;
						this.sub_branches = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchDepartments() {
				let url = "/api/reports/departments";
				this.getToken();
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data;
						this.departments = responseData.data;
					})
					.catch((err) => console.log(err));
			},

			fetchCourses() {
				let url = "/api/reports/courses";
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data.data;
						this.courses = responseData;
					})
					.catch((err) => console.log(err));
			},
            
            fetchCentres() {
				let url = "/api/reports/centres";
				this.$axios
					.get(url, {
						headers: { Authorization: "Bearer " + this.token },
					})
					.then((res) => {
						var responseData = res.data.data;
						this.centres = responseData;
					})
					.catch((err) => console.log(err));
			},

			fetchStudents(page_url=null) 
		    {
	          let url = '/api/reports?limit=' + this.listing_length + '&keyword=' + this.keyword + '&course_id=' + this.course + '&session_id=' + this.session  + '&state_id=' + this.state + '&district_id=' + this.district + '&branch_id=' + this.branch + '&subbranch_id=' + this.sub_branch + '&department_id=' + this.department + '&centre_id=' + this.centre;
	          // alert(this.state);
	          page_url = page_url || url;  
	          this.active_page_url = page_url;      	            
	          this.getToken();
	          this.$axios
	            .get(page_url,{headers: {Authorization: 'Bearer ' + this.token,}})
	            .then(res => {
	               var responseData = res.data.data.students;
	               this.students   = responseData.data;               
	               this.pagination = responseData.links;
	               this.table_visible= true;
	               console.log(res.data.data.students.data);	               
	            })
	            .catch(err => console.log(err));
	        }, 

	        generatePDF() {
				this.getToken();
				let page_url = '/api/reports/download?limit=' + this.listing_length + '&keyword=' + this.keyword + '&course_id=' + this.course + '&session_id=' + this.session  + '&state_id=' + this.state + '&district_id=' + this.district + '&branch_id=' + this.branch + '&subbranch_id=' + this.sub_branch + '&department_id=' + this.department + '&centre_id=' + this.centre;
				this.$axios
					.get(page_url, { headers: { Authorization: "Bearer " + this.token }, responseType: "arraybuffer", })
					.then((response) => {
						// alert(response);
						console.log(response);
						let blob = new Blob([response.data], { type: "application/pdf" });
						let link = document.createElement("a");
						link.href = window.URL.createObjectURL(blob);
						link.download = "studentReport.pdf";
						// document.body.appendChild(link);
						link.click();
						
					})
					.catch((err) => console.log(err));
			},
 
		},
	};
</script>
